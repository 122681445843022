import type DataColumn from 'o365.controls.DataGrid.Column.ts';
import type { FieldType } from 'o365.modules.DataObject.Fields.ts';
import type { DataItemModel, ItemModel } from 'o365.modules.DataObject.Types.ts';
import utils from 'o365.modules.utils.js';

export interface Location {
    x: number;
    y: number;
    container: GridSelectionContainer
}

enum GridSelectionContainer {
    Header = 'H',
    NewRecords = 'N',
    Filter = 'F',
    Main = 'G',
    Summary = 'S'
};

export interface AreaSelection {
    start: Location;
    end: Location;
}

export type SelectedColumn = DataColumn | {
    name: string;
    caption: string;
    type: FieldType;
    hide?: boolean;
    unbound?: boolean;
    colId: string;
    getCopyValue?: (pColumn: DataColumn) => any;
};

abstract class SelectionControl<T extends ItemModel = ItemModel> {
    selection?: AreaSelection;
    allRowsSelected?: boolean;

    protected _isPasting: boolean = false;
    get isPasting() { return this._isPasting; }

    constructor() {

    }

    abstract onSelection(pIndexOrRow: number | T, pValue: boolean): void;

    protected get isValidSelection() {
        return this.selection && this.selection.hasOwnProperty('start') && this.selection.hasOwnProperty('end');
    }

    get areaselection() {
        return this.selection;
    }

    set areaselection(selection) {
        if (JSON.stringify(selection) !== JSON.stringify(this.selection)) {
            this.selection = selection;
        }
    }

    abstract get selectedRows(): T[] | DataItemModel<T>[];

    abstract isAllRowsSelected(): boolean;

    abstract isSomeRowsSelected(): boolean;

    abstract getSelection(columnsArray: DataColumn[], rowData?: any[], pOptions?: {
        valueResolve?: (pColumn: any, pRow: DataItemModel<T>) => [string, any]
    }): Record<string, any>[] | undefined

    abstract getSelectedRowsData(columnArray: SelectedColumn[]): Record<string, any>[];

    protected checkColumnInclusion(column: SelectedColumn) {
        return !column.name.startsWith('o365') && !column.hide
    }

    protected getColumnsForCopy(selectedRows: T[] | DataItemModel<T>[], gridColumns: SelectedColumn[], pOptions?: ColumnCopyOptions) {
        return selectedRows.length ?
            gridColumns.filter((column) => { return this.checkColumnInclusion(column) })
            : this.getColumns(gridColumns, pOptions);
    };

    abstract selectAll(setSelectionTo: boolean): Promise<void> | void;

    unselectAll() {
        this.selectAll(false);
        this.allRowsSelected = false;
    }

    protected getRows<T1>(data: T1[]): T1[] | undefined {
        if (this.selection && this.isValidSelection) {
            const rows = this.selection.end.y - this.selection.start.y >= 0
                ? data.slice(this.selection.start.y, this.selection.end.y + 1)
                : data.slice(this.selection.end.y, this.selection.start.y + 1);
            return rows;
        } else {
            return undefined;
        }
    };

    protected getColumns<T1 extends SelectedColumn>(data: T1[], pOptions?: ColumnCopyOptions) {
        if (this.selection && this.isValidSelection) {
            const columns = this.selection.end.x - this.selection.start.x >= 0
                ? data.slice(this.selection.start.x, this.selection.end.x + 1)
                : data.slice(this.selection.end.x, this.selection.start.x + 1);
            if (['o365_System', 'o365_MultiSelect'].includes(columns[0].colId)) {
                if (pOptions) {
                    pOptions.isFullRow = true;
                }
                return data.filter((column) => { return this.checkColumnInclusion(column) });
            } else {
                return columns?.filter(x => !x.hide);
            }
        } else {
            return undefined;
        }
    };

    protected modifyAreaSelection(parsedColumnLength: number, parsedTextLength: number, expandAreaSelection: boolean = true, selectedColumnLength?: number, selectedRowLength?: number) {
        const modifiedObject = {
            start: { x: 0, y: 0, container: '' as GridSelectionContainer },
            end: { x: 0, y: 0, container: '' as GridSelectionContainer }
        };

        this.areaselection = this.convertCoordinates();

        modifiedObject.start.container = this.areaselection.start.container;
        modifiedObject.end.container = this.areaselection.end.container;

        if (expandAreaSelection) {
            modifiedObject.start.x = this.areaselection!.start.x;
            modifiedObject.start.y = this.areaselection!.start.y;
            modifiedObject.end.x = this.areaselection!.end.x + parsedColumnLength - (selectedColumnLength ?? 1);
            modifiedObject.end.y = this.areaselection!.end.y + parsedTextLength - (selectedRowLength ?? 1);
        } else {
            modifiedObject.start.x = this.areaselection!.start.x;
            modifiedObject.start.y = this.areaselection!.start.y;
            modifiedObject.end.x = this.areaselection!.start.x + parsedColumnLength - 1;
            modifiedObject.end.y = this.areaselection!.start.y + parsedTextLength - 1;
        }

        this.areaselection = modifiedObject;
    };

    protected convertCoordinates() {
        const newCoordinates = {
            start: { x: 0, y: 0, container: '' as GridSelectionContainer },
            end: { x: 0, y: 0, container: '' as GridSelectionContainer }
        };

        newCoordinates.start.container = this.areaselection!.start.container;
        newCoordinates.end.container = this.areaselection!.end.container;

        newCoordinates.start.x = Math.min(this.areaselection!.start.x, this.areaselection!.end.x);
        newCoordinates.start.y = Math.min(this.areaselection!.start.y, this.areaselection!.end.y);
        newCoordinates.end.x = Math.max(this.areaselection!.start.x, this.areaselection!.end.x);
        newCoordinates.end.y = Math.max(this.areaselection!.start.y, this.areaselection!.end.y);

        return newCoordinates;
    }

    protected parsedColumnLength(parsedText: string[][]) {
        let parsedColumnLength: number;
        parsedText.every((col) => {
            if (typeof parsedColumnLength === 'undefined') {
                parsedColumnLength = col.length; return true;
            }
            else {
                return parsedColumnLength === col.length;
            }
        });
        return parsedColumnLength!;
    }

    abstract copySelection(withHeaders: boolean, gridColumns: SelectedColumn[], copyAsJson: boolean): Promise<void> | void;

    abstract handleJsonPaste(text: string, columns: DataColumn[]): boolean | Record<string, any>[];

    abstract pasteSelection(event: KeyboardEvent, columns: DataColumn[]): Promise<boolean | undefined> | void;

    protected formatForCopyValue(value: any, colType: string) {
        return utils.formatForCopyValue(value,colType);
    }

    protected formatForPasteValue(value: any, colType: string) {
        let returnValue;
        if (value === null) {
            return "";
        }
        switch (colType) {
            case "number":
                if (value) {
                    returnValue = (parseFloat(value.replace(",", ".").replace(/ /g, "")));
                } else {
                    returnValue = (null);
                }
                break;
            case "bit":
            case "boolean":
                if (value === "USANN" || value === "FALSE" || !value || value === 0 || value === "0") {
                    returnValue = (false);
                } else {
                    returnValue = (true);
                }
                break;
            case "time":
                returnValue = (value === "" ? null : utils.formatDate(this.cleanValue(value), "HH:mm"));
                break;
            case "date":
                returnValue = (utils.date.parseDate(this.cleanValue(value)));
                break;
            case "datetime":
                returnValue = utils.date.parseDate(this.cleanValue(value));
                break;
            default:
                returnValue = value.replace(new RegExp('^\\n'), '');
                // returnValue = (value ? value.replace(new RegExp(String.fromCharCode(10), 'g'), " ") : value);
                break;
        }

        return returnValue !== null ? returnValue : "";
    }
    protected cleanValue(pValue: any) {
        if (!pValue) return;
        return pValue.replace(/(?:\r\n|\r|\n)/g, '');
    }

    protected determinePasteSelection(event: KeyboardEvent) {
        const pasteSelection: {
            startAtIndex: number | null;
            columnStartAtIndex: number | null
        } = { startAtIndex: null, columnStartAtIndex: null };

        let rowElement: HTMLElement | null = (event.target as HTMLElement)?.closest('[data-o365-rowindex]');
        let colElement: HTMLElement | null = (event.target as HTMLElement)?.closest('[data-o365-colindex]');

        if (rowElement && colElement) {
            const rowIndex = rowElement.getAttribute('data-o365-rowindex');
            const columnIndex = colElement.getAttribute('data-o365-colindex');

            pasteSelection['startAtIndex'] = Number(rowIndex);
            pasteSelection['columnStartAtIndex'] = Number(columnIndex);
        } else {
            rowElement = (event.target as HTMLElement)?.closest('[data-o365-rowindex]');
            colElement = (event.target as HTMLElement)?.closest('[data-o365-colindex]');
            if (rowElement && colElement) {
                const rowIndex = rowElement.dataset.o365Rowindex;
                const columnIndex = colElement.dataset.o365Colindex;

                pasteSelection['startAtIndex'] = Number(rowIndex);
                pasteSelection['columnStartAtIndex'] = Number(columnIndex);
            }
        }

        return pasteSelection;
    }

    /**
     * Set selection in a range, pEnd cannot be greater than pStart
     * @param pValue value to set to
     * @param pStart range start
     * @param Pend range end
     */
    abstract selectRange(pValue: boolean, pStart: number, pEnd: number): Promise<void> | void;

    protected isEditable(pColumn: DataColumn, pRow: any) {
        return typeof pColumn.editable === 'function'
            ? (pColumn.editable as ((pItem: DataItemModel<T>) => boolean))(pRow)
            : pColumn.editable;
    }

    protected applyValue(pRow: any, pColumn: any, pRawValue: any) {
        if (pColumn.onPaste) {
            pColumn.onPaste(pRow, this.formatForPasteValue(pRawValue, pColumn.type), pColumn);
        } else if (this.isEditable(pColumn, pRow)) {
            (pRow[pColumn.name] as any) = this.formatForPasteValue(pRawValue, pColumn.type);
        }
    }
}

export type ColumnCopyOptions = {
    isFullRow: boolean,
};

export { SelectionControl }; 